import formatInputFloat from "@/app/infrastructures/misc/common-library/FormatInputFloat";
import {
  ColumnManifestData,
  SectionManifestData
} from "@/domain/entities/MainApp";
import jsPDF from "jspdf";
import autoTable, { ColumnInput } from "jspdf-autotable";
import {
  convertDecimalWithComma,
  ellipsisString,
  formatDate,
} from "@/app/infrastructures/misc/Utils";

const margin = {
  left: 10,
  right: 10,
  top: 10,
  bottom: 10
};
let doc: any = new jsPDF(),
  title = "",
  columnData: ColumnInput[] = [],
  currentSection = 1,
  nextSection = 1,
  startY = margin.top + 13,
  startXSignature = 90;

const tableDataProcess = (params: SectionManifestData) => {
  /* TABLE DATA */
  const tableColumnsData = [
    {
      header: "No. STT",
      dataKey: "sttNo"
    },
    {
      header: "No. referensi",
      dataKey: "refNo"
    },
    {
      header: "Total Koli",
      dataKey: "totalPiece"
    },
    {
      header: "Total Berat",
      dataKey: "totalGrossWeight"
    }
  ];

  // get & sort list column data
  for (const section of params.columns) {
    for (const detail of tableColumnsData) {
      if (detail.dataKey === section) {
        columnData.push(detail);
      }
    }
  }
  // add propery no each rows
  if (params.columns.length) {
    columnData.unshift({
      header: "No.",
      key: "no"
    });
  }
  //   add column No.
  params.data = params.data.map(
    (item: ColumnManifestData, index: number) =>
      new ColumnManifestData({
        ...item,
        no: index + 1
      })
  );
  /* END TABLE DATA */
};

const signatureProcess = (params: SectionManifestData) => {
  // if there's remaining vertical space in page: start printing next table from the current section
  const remainingVSpace =
    doc.internal.pageSize.height -
    margin.top -
    margin.bottom -
    doc.lastAutoTable.finalY;
  const spaceSignature = 20;

  if (remainingVSpace > 19.5 && currentSection === 1) {
    nextSection = currentSection;
    startY = doc.lastAutoTable.finalY + 10;
  } else {
    startY = margin.top;
    doc.addPage();
  }

  if (params.signature) {
    doc.setFont("Poppins", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    startXSignature += 6;
    doc.text("Station Admin", startXSignature, startY);
    doc.setFont("Poppins", "normal");
    startXSignature += 44;
    doc.text("Kurir", startXSignature, startY);
    doc.setFont("Poppins", "normal");
    startXSignature += 27;
    doc.text("Operation Supervisor", startXSignature, startY);
    startXSignature -= 77;

    startXSignature += 2;
    doc.text(
      "(...........................................)",
      startXSignature,
      startY + spaceSignature
    );
    startXSignature += 38;
    doc.text(
      "(...........................................)",
      startXSignature,
      startY + spaceSignature
    );
    startXSignature += 38;
    doc.text(
      "(...........................................)",
      startXSignature,
      startY + spaceSignature
    );
  }
};

const onResetData = () => {
  title = "";
  columnData = [];
  currentSection = 1;
  nextSection = 1;
  startY = margin.top + 13;
  startXSignature = 90;
};

const setPrintTitleAndLogo = (doc: any, params: SectionManifestData) => {
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text(`STI SC manifest - ${params.headerDetailData.stiScId}`, 8, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(8, 25, 194, 0.2, "F");
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
};

const setPrintLeftHeaderColumn = (doc: any, params: SectionManifestData) => {
  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");
  doc.text("Nama sub consol", 10, 32);
  doc.text(":", 36, 32);
  doc.text(ellipsisString(params.headerDetailData.partnerName, 70), 38, 32);

  doc.setTextColor("#4d4d4d");
  doc.text("Kota sub consol", 10, 38);
  doc.text(":", 36, 38);
  doc.text(params.headerDetailData.originCity, 38, 38);
};

const setPrintMiddleHeaderColumn = (doc: any, params: SectionManifestData) => {
  // middle column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total STT/Koli", 75, 32);
  doc.text(":", 100, 32);
  doc.text(
    `${params.headerDetailData.totalStt}/${params.headerDetailData.totalPiece}`,
    102,
    32
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total berat kotor", 75, 38);
  doc.text(":", 100, 38);
  doc.text(
    `${convertDecimalWithComma(
      params.headerDetailData.totalGrossWeight,
      2
    )} kg`,
    102,
    38
  );
};

const setPrintRightHeaderColumn = (doc: any, params: SectionManifestData) => {
  // right column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Tanggal manifest", 128, 32);
  doc.text(":", 163, 32);
  doc.text(
    `${formatDate(
      params.headerDetailData.createdAt,
      "DD MMMM YYYY, HH:mm:ss"
    )}`,
    165,
    32
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total STT belum diterima", 128, 38);
  doc.text(":", 163, 38);
  doc.text(`${params.headerDetailData.needToStiScTotalStt} STT`, 165, 38);
};

export const generateManifest2ColumnPotrait = async (
  params: SectionManifestData
): Promise<void> => {
  doc = new jsPDF("p", "mm", "a4", true);

  // logo
  const importLogo = await import(
    `@/app/ui/assets/images/logo-sub-console.png`
  );
  const logo = importLogo.default;

  startY += 25;

  tableDataProcess(params);

  // number of table sections in the page
  const sections = 2;

  // space between each section
  const spacing = 5;

  // calculate each section width
  const printWidht = doc.internal.pageSize.width - (margin.left + margin.right);
  const sectionWidth = (printWidht - (sections - 1) * spacing) / sections;

  // add an initial empty page that will be delete later,
  // it is needed for the first setPage(previous_page) call
  doc.addPage();

  autoTable(doc, {
    theme: "plain",
    body: params.data,
    columns: columnData,
    styles: {
      font: "Poppins",
      halign: "left",
      fontSize: 5,
      textColor: "#4D4D4D"
    },
    headStyles: {
      fontStyle: "bold",
      fillColor: "#F5F6F7",
      cellPadding: {
        top: 2,
        right: 1,
        bottom: 2,
        left: 1
      }
    },
    columnStyles: {
      0: { cellWidth: 10, fontSize: 5 },
      1: { cellWidth: 20, fontSize: 5 },
      2: { cellWidth: 34, fontSize: 5 },
      3: { cellWidth: 15, fontSize: 5 },
      4: { cellWidth: 15, fontSize: 5 }
    },
    bodyStyles: {
      fontStyle: "normal",
      valign: "middle",
      cellPadding: {
        top: 1.2,
        right: 1.3,
        bottom: 1.2,
        left: 1.3
      },
      cellWidth: "auto",
      lineColor: "#4D4D4D"
    },
    tableWidth: sectionWidth,
    margin: {
      left: margin.left + (nextSection - 1) * (sectionWidth + spacing),
      top: startY,
      bottom: margin.bottom
    },
    startY,
    rowPageBreak: "avoid", // avoid breaking rows into multiple sections
    didDrawPage({ table }: any) {
      /* HEADER SECTION */
      if (doc.internal.getNumberOfPages() === 2 && nextSection === 1) {
        // get logo
        const pdfWidth = doc.internal.pageSize.width;
        const logoProperties = doc.getImageProperties(logo);
        const logoAspectRatio = logoProperties.height / logoProperties.width;

        const imageWidth = 40;
        const imageHeight = logoAspectRatio * 40;

        doc.addImage(
          logo,
          "PNG",
          pdfWidth - imageWidth - 9,
          11,
          imageWidth,
          imageHeight,
          "logo",
          "FAST",
          0
        );

        // set title and logo
        setPrintTitleAndLogo(doc, params);

        // set left header column
        setPrintLeftHeaderColumn(doc, params);

        // set middle header column
        setPrintMiddleHeaderColumn(doc, params);

        // set right header column
        setPrintRightHeaderColumn(doc, params);
      }

      currentSection = nextSection;
      nextSection = (nextSection % sections) + 1;

      // set left margin which will controll x position of next section
      const shift = (nextSection - 1) * (sectionWidth + spacing);
      table.settings.margin.left = margin.left + shift;

      // if next section is not the fist, move to previous page so when
      // autoTable calls addPage() it will still be the same current page
      if (doc.internal.getNumberOfPages() === 2 && nextSection === 1) {
        table.settings.margin.top = 15;
      }
      if (nextSection > 1) {
        doc.setPage(doc.internal.getNumberOfPages() - 1);
      }

      /* END HEADER SECTION */
    },
    willDrawCell: (data: any) => {
      // add borders bottom each column
      doc.setDrawColor("#E0E0E0"); // set the border color
      doc.setLineWidth(0.2); // set the border with

      if (data.section === "head") {
        // draw top border each row
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        );
      }

      if (data.column.index === 0) {
        // draw left border
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x,
          data.cell.y
        );
      } else if (data.column.index === columnData.length - 1) {
        // draw right border
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        );
      }

      // draw bottom border each row
      doc.line(
        data.cell.x,
        data.cell.y + data.cell.height,
        data.cell.x + data.cell.width,
        data.cell.y + data.cell.height
      );
    },
    didParseCell: (data: any) => {
      for (const body of data.table.body) {
        // parse string for weight with Kg
        if (data.column.dataKey === "totalGrossWeight") {
          body.cells["totalGrossWeight"].text = [
            `${formatInputFloat(body.raw["totalGrossWeight"])} kg`
          ];
        }
      }
    }
  });

  // activate last page for further printing
  doc.setPage(doc.internal.getNumberOfPages());

  signatureProcess(params);

  // delete unused empty page
  doc.deletePage(1);

  doc.setProperties({
    title
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
  onResetData();
};
